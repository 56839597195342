import React from 'react'

const BaseInputButton = ({ buttonClass, text, id }) => {
  return (
    <button
      id={id}
      className={`${buttonClass} mt-6 lg:mt-0 -mb-1 h-11 mr-15  lg:mr-10 self-end Button Button--primary`}
    >
      {text}
    </button>
  )
}

export default BaseInputButton
