import React from 'react'

const BaseInput = ({ inputClass, inputLabel, id, invalid, errorMessage, value, handleInput, maxLength }) => {
  return (
    <div className={`${inputClass} relative`}>
      <label className="text-blue-500 text-sm" htmlFor={id}>{inputLabel}</label>
      <input
        type="text"
        maxLength={maxLength ? maxLength : null}
        id={id}
        className="bg-blue-200 h-12 md:h-9 px-2 w-full focus:outline-none appearance-none border-2 border-transparent focus:border-blue-500"
        onInput={(e) => handleInput(id, e.target.value)}
        value={value} />
      {invalid && errorMessage ? <div className="text-red-500 text-xs absolute -bottom-5">{errorMessage}</div> : null}
    </div>
  )
}

export default BaseInput