import React, { useState } from "react"
import PropTypes from "prop-types"

import BaseForm from "../../Forms/BaseForm"
import BaseInput from "../../Forms/BaseInput"
import BaseInputButton from "../../Forms/BaseInputButton"

const SubscriptionForm = ({ data }) => {
  const [firstName, setFirstName] = useState("")
  const [firstNameIsInvalid, setFirstNameIsInvalid] = useState(false)
  const [lastName, setLastName] = useState("")
  const [lastNameIsInvalid, setLastNameIsInvalid] = useState(false)
  const [email, setEmail] = useState("")
  const [emailIsInvalid, setEmailIsInvalid] = useState(false)
  const [postcode, setPostcode] = useState("")
  const [postcodeIsInvalid, setPostcodeIsInvalid] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [submissionMessage, setSubmissionMessage] = useState("")

  const handleFirstNameValidation = () => {
    setFirstNameIsInvalid(firstName === "" || firstName.length > 255)
    const validation = firstName === "" || firstName.length > 255
    return validation
  }
  const handleLastNameValidation = () => {
    const validation = lastName === "" || lastName.length > 255
    setLastNameIsInvalid(validation)
    return validation
  }

  const handleEmailValidation = () => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const validation = !regex.test(String(email).toLowerCase())
    setEmailIsInvalid(validation)
    return validation
  }

  const handlePostcodeValidation = () => {
    const validation = isNaN(postcode) || postcode.length > 4 || postcode === ""
    setPostcodeIsInvalid(validation)
    return validation
  }

  const handleInvalidFields = () => {
    const firstNameIsInvalid = handleFirstNameValidation()
    const lastNameIsInvalid = handleLastNameValidation()
    const emailIsInvalid = handleEmailValidation()
    const postcodeIsInvalid = handlePostcodeValidation()

    return (
      firstNameIsInvalid ||
      lastNameIsInvalid ||
      emailIsInvalid ||
      postcodeIsInvalid
    )
  }

  /**
   * Sets the specified field to the value of the input.
   * Also clears the error message of the input
   *
   * @param id ID of the input being handled
   * @param value value of the input being handled
   */
  const handleInput = (id, value) => {
    switch (id) {
      case "firstname":
        setFirstName(value)
        setFirstNameIsInvalid(false)
        break

      case "lastname":
        setLastName(value)
        setLastNameIsInvalid(false)
        break

      case "email":
        setEmail(value)
        setEmailIsInvalid(false)
        break

      case "postcode":
        setPostcode(value)
        setPostcodeIsInvalid(false)
        break

      default:
        break
    }
  }

  const mapData = () => {
    return {
      fields: [
        {
          name: "firstname",
          value: firstName,
        },
        {
          name: "lastname",
          value: lastName,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "zip",
          value: postcode,
        },
        {
          name: "lifecyclestage",
          value: "Subscriber",
        },
      ],
    }
  }

  const makeRequest = async (url, data) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

    return await response.json()
  }

  /**
   * Handles the errors returned by HubSpot (if any)
   * @param { Array } errors
   */
  const handleErrors = ({ errors }) => {
    if (errors[0].errorType === "INVALID_EMAIL") setEmailIsInvalid(true)
  }

  /**
   * Sets the success message to be displayed when the form is successfully submitted.
   * This text is specified in HubSpot > Marketing > Forms > Contact Form
   *
   * @param { String } inlineMessage
   */
  const handleSubmitted = ({ inlineMessage }) => {
    setHasSubmitted(true)
    setSubmissionMessage(inlineMessage.replace(/(<([^>]+)>)/gi, "")) //strips tags
  }

  const handleResponse = response => {
    if (response.errors?.length) {
      handleErrors(response)
    } else {
      handleSubmitted(response)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (handleInvalidFields()) {
      return
    }

    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_FRANKIE_PORTAL_ID}/${process.env.GATSBY_CONTACT_FORM_GUID}`
    const data = mapData()

    makeRequest(url, data)
      .then(response => {
        handleResponse(response)
      })
      .catch(err => {
        console.error(err)
      })
  }

  return (
    <div className="mx-ogs flex flex-col items-center py-20 lg:grid lg:grid-cols-12 lg:gap-x-gs lg:py-24">
      <div className="col-span-10 col-start-2 flex w-full flex-col items-center justify-center">
        <h3 className="pb-4 text-center text-3xl font-semibold lg:text-4xl">
          {data?.heading || "Stay informed"}
        </h3>
        <p className="pb-8 text-center text-base">
          {data?.subheading ||
            "Subscribe to Frankie to receive regular news and updates"}
        </p>
      </div>

      <div className="col-span-10 col-start-2 w-full">
        <BaseForm
          handleSubmit={handleSubmit}
          hasSubmitted={hasSubmitted}
          submissionMessage={submissionMessage}
        >
          <BaseInput
            inputClass={"col-span-6"}
            inputLabel={"First Name"}
            id={"firstname"}
            value={firstName}
            handleInput={handleInput}
            invalid={firstNameIsInvalid}
            errorMessage={"Please enter a first name"}
            maxLenght={255}
          />

          <BaseInput
            inputClass={"col-span-6"}
            inputLabel={"Last Name"}
            id={"lastname"}
            value={lastName}
            handleInput={handleInput}
            invalid={lastNameIsInvalid}
            errorMessage={"Please enter a last name"}
            maxLength={255}
          />

          <BaseInput
            inputClass={"col-span-6"}
            inputLabel={"Email"}
            id={"email"}
            value={email}
            handleInput={handleInput}
            invalid={emailIsInvalid}
            errorMessage={"Please enter a valid email"}
            maxLength={255}
          />

          <BaseInput
            inputClass={"col-span-6 md:col-span-3"}
            inputLabel={"Postcode"}
            id={"postcode"}
            value={postcode}
            handleInput={handleInput}
            invalid={postcodeIsInvalid}
            errorMessage={"Please enter a valid postcode"}
            maxLength={4}
          />

          <BaseInputButton
            buttonClass={"col-span-6 md:col-span-3"}
            text={"Subscribe"}
          />
        </BaseForm>
      </div>
    </div>
  )
}

SubscriptionForm.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
  }),
}

export default SubscriptionForm
