import React, { useEffect, useState, useRef } from "react"

const BaseForm = ({
  children,
  handleSubmit,
  hasSubmitted,
  submissionMessage,
}) => {
  const formEl = useRef(null)
  const [formHeight, setFormHeight] = useState(0)

  useEffect(() => {
    // Sets the element height of the form at render so it stays the same when the fields disappear and
    // the success message is shown
    setFormHeight(formEl.current.offsetHeight)
  }, [])

  return (
    <div className="w-full rounded-xl bg-white py-8 md:pt-6 md:pb-11 px-4 md:px-8">
      {hasSubmitted ? (
        <div
          className="w-full flex items-center justify-center"
          style={{ height: `${formHeight}px` }}
        >
          <span className="text-center">{submissionMessage}</span>
        </div>
      ) : (
        <form
          ref={formEl}
          onSubmit={e => handleSubmit(e)}
          className="w-full grid grid-cols gap-6 md:grid md:grid-cols-12 md:gap-gs"
        >
          {children}
        </form>
      )}
    </div>
  )
}

export default BaseForm
